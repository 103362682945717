<template>
	<v-tooltip :top="tooltipPosition === 'top'" :left="tooltipPosition === 'left'" :right="tooltipPosition === 'right'" :bottom="tooltipPosition === 'bottom'">
		<template v-slot:activator="{ on }">
			<v-btn color="primary" :icon="hasOnlyIcon" depressed :flat="hasOnlyIcon || value" v-on="on" @click="toggle">
				<v-icon v-if="hasIcon">{{ icon }}</v-icon>
				<span v-text="hasText ? text : null" />
			</v-btn>
		</template>
		<span v-if="hasTooltip" v-text="tooltipText" />
	</v-tooltip>
</template>

<script>
export default {
	name: 'ToggleButton',
	props: {
		value: {
			required: true,
			type: Boolean
		},
		iconOn: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		iconOff: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		textOn: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		textOff: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltip: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltipOn: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltipOff: {
			required: false,
			type: String,
			default: null,
			validator: propValue => (propValue ? propValue.trim().length : true)
		},
		tooltipPosition: {
			required: false,
			type: String,
			default: 'bottom',
			validator: propValue => ['top', 'left', 'right', 'bottom'].indexOf(propValue) !== -1
		}
	},
	computed: {
		hasIcon: function () {
			return this.iconOn && this.iconOff
		},
		icon: function () {
			return this.value ? this.iconOn : this.iconOff
		},
		hasText: function () {
			return this.textOn && this.textOff
		},
		text: function () {
			return this.value ? this.textOn : this.textOff
		},
		hasOnlyIcon: function () {
			return this.hasIcon && !this.hasText
		},
		hasTooltip: function () {
			return this.tooltip || (this.tooltipOn && this.tooltipOff)
		},
		tooltipText: function () {
			let result = null
			if (this.tooltip) {
				result = this.$t(this.tooltip)
			} else if (this.tooltipOn && this.value) {
				result = this.$t(this.tooltipOn)
			} else if (this.tooltipOff && !this.value) {
				result = this.$t(this.tooltipOff)
			}
			return result
		}
	},
	methods: {
		toggle: function () {
			this.$emit('input', !this.value)
		}
	}
}
</script>
